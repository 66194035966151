Daily Word: Daily Word

# Login buttons
Sign-in: Sign in
Sign-out: Sign out
Subscribe: Subscribe

# Splash page
Discover: Discover
Grow: Grow
Experience: Experience

# Callouts
More from Unity.org: 'More from Unity.org'

Daily Word Magazine: Daily Word Magazine
The Latest on Unity.org: The Latest on Unity.org
Unity Magazine: Unity Magazine

# Focused layout
Back to app: Back to app
Close: Close
Dismiss: Dismiss

E-mail: E-mail

# Pager
Previous: Previous
Next: Next

# Preferences
Preferences: Preferences
Login Options: Login Options
Preferred Language: Preferred Language
Color Scheme: Color Scheme
Start Page: Start Page
Notifications: Notifications
Options: Options
How to install?: How to install?

pager:
  previous:
    label: Previous
  next:
    label: Next

navbar:
  prayer:
    label: Request a Prayer
    description: Add your prayer requests to the Unity Prayer Ministry
  daily-word:
    label: Today's word
    description: Subscribers log in to read or listen to the Daily Word message
  playlist:
    label: Meditate+
    description: Watch the latest inspirational and meditative videos from Unity

theme:
  auto:
    label: Auto
    description: Use & change colors based on system preferences
  light:
    label: Light
    description: Light mode uses a light background with dark text
  dark:
    label: Dark
    description: Dark mode uses a dark background with lighter text

locales:
  en:
    label: English
    description: ''
  es:
    label: Spanish
    description: ''
